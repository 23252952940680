<template>
  <div class="login-page">
    <div class="mb-14">
      <span class="font-32 font-700 d-block">Verify your account.</span>
      <span class="font-24 font-500 d-block darkprimary--text"
        >{{$t('forms.checkEmail')}}</span
      >
    </div>
    <v-form ref="loginForm" v-model="valid" @submit="submitHandler">
      <!-- password -->
      <div class="mt-6">
        <span class="darktext--text font-18 font-600">{{$t('forms.verificationCode')}}</span>
        <base-input
          class="mt-1"
          type="email"
          :placeholder="$t('forms.enterVerificationCode')"
          :rules="emailRules"
          v-model="obj.email"
          :hasValue="obj.email"
        ></base-input>
      </div>

      <!-- btn -->
      <base-register-btn
        class="mt-7"
        title="Verify"
        type="submit"
        :loading="loading"
      >
      </base-register-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    loading: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    obj: {}
  }),
  methods: {
    submitHandler() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style></style>
